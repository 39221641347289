<template>
  <Toast />
  <div class="flex align-items-center py-5 px-0">
    <div class="surface-card p-4 shadow-2 border-round w-full">
      <div class="text-center mb-5">
        <img
          src="images/coachera-logo-sm.png"
          alt="Image"
          height="50"
          class="mb-3"
        />
        <div class="text-900 text-3xl font-medium mb-3">Welcome</div>
      </div>

      <div>
        <div class="grid">
          <div class="col">
            <label for="first-name" class="block text-900 font-medium mb-2"
              >First Name</label
            >
            <InputText
              id="first-name"
              type="text"
              class="w-full mb-3"
              :class="{ 'p-invalid': v$.first_name.$invalid && submitted }"
              v-model="first_name"
            />
            <small
              v-if="
                (v$.first_name.$invalid && submitted) ||
                v$.first_name.$pending.$response
              "
              class="p-error"
              >{{
                v$.first_name.required.$message.replace("Value", "First name")
              }}</small
            >
          </div>
          <div class="col">
            <label for="last-name" class="block text-900 font-medium mb-2"
              >Last Name</label
            >
            <InputText
              id="last-name"
              type="text"
              class="w-full mb-3"
              :class="{ 'p-invalid': v$.last_name.$invalid && submitted }"
              v-model="last_name"
            />
            <small
              v-if="
                (v$.last_name.$invalid && submitted) ||
                v$.last_name.$pending.$response
              "
              class="p-error"
              >{{
                v$.last_name.required.$message.replace("Value", "Last name")
              }}</small
            >
          </div>
        </div>
        <label
          for="email"
          class="block text-900 font-medium mb-2"
          :class="{ 'p-error': v$.email.$invalid && submitted }"
          >E-Mail</label
        >
        <InputText
          id="email"
          type="text"
          class="w-full mb-3"
          :class="{ 'p-invalid': v$.email.$invalid && submitted }"
          v-model="email"
        />
        <span
          v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response"
        >
          <span id="email-error">
            <small class="p-error">{{ v$.email.email.$message }}</small>
            <br />
            <small v-if="v$.email.required.$invalid" class="p-error">{{
              v$.email.required.$message.replace("Value", "Email")
            }}</small>
          </span>
        </span>

        <label for="country" class="block text-900 font-medium mb-2"
          >Country of residence</label
        >
        <AutoComplete
          forceSelection
          placeholder="Country"
          id="country"
          :dropdown="true"
          :multiple="false"
          v-model="selectedAutoValue"
          :suggestions="autoFilteredValue"
          @complete="searchCountry($event)"
          field="name"
        />
        <br />
        <br />

        <label for="time_pref" class="block text-900 font-medium mb-2"
          >Coaches time preference</label
        >
        <Dropdown
          id="time_pref"
          v-model="dropdownValue"
          :options="dropdownValues"
          optionLabel="name"
          placeholder="Select time preference"
        />
        <br />
        <br />

        <label for="xlm_public_key" class="block text-900 font-medium mb-2"
          ><b>Optional</b>: Stellar (XLM) public key
          <Button
            icon="pi pi-question"
            class="p-button-rounded p-button-text"
            @click="open"
        /></label>
        <InputText
          id="xlm_public_key"
          type="text"
          class="w-full mb-3"
          v-model="xlm_public_key"
          placeholder="You can also add your key later."
        />

        <Dialog
          header="The ludi token"
          v-model:visible="display"
          :breakpoints="{ '960px': '75vw' }"
          :style="{ width: '30vw' }"
          :modal="true"
        >
          <p class="line-height-3 m-0">
            As one of the very early users of the platform you are eligible for
            free ludi tokens. These tokens are based on the Stellar network. The
            more you interact with the platform, the more tokens you will get.
            Later you can redeem them for networking events or special events,
            or sell them on the open market.
          </p>
          <p>
            To receive tokens, you need a Stellar wallet. There are many wallets
            - one of the most user-friendly and popular wallets is
            <a href="https://lobstr.co/">Lobstr</a>. After creating a Lobstr
            account, you can view your Stellar address
            <a
              href="https://lobstr.zendesk.com/hc/en-us/articles/360000869413-Where-can-I-find-my-Stellar-wallet-address-"
              >here</a
            >. Paste that address in the textbox to get an airdrop of ludi
            tokens.
          </p>
          <template #footer>
            <Button
              label="Dismiss"
              @click="close"
              icon="pi pi-check"
              class="p-button-secondary"
            />
          </template>
        </Dialog>

        <label
          for="password"
          class="block text-900 font-medium mb-2"
          :class="{ 'p-error': v$.password.$invalid && submitted }"
          >Password</label
        >
        <!-- <InputText
          id="password"
          type="password"
          class="w-full mb-3"
          :class="{ 'p-invalid': v$.password.$invalid && submitted }"
          v-model="password"
        />
        <small
          v-if="
            (v$.password.$invalid && submitted) ||
            v$.password.$pending.$response
          "
          class="p-error"
          >{{
            v$.password.required.$message.replace("Value", "Password")
          }}</small
        > -->
        <InputText
          id="password"
          type="password"
          v-model="v$.password.password.$model"
          class="w-full mb-3"
          :class="{ 'p-invalid': v$.password.password.$invalid && submitted }"
        />
        <small
          v-if="
            (v$.password.password.$invalid && submitted) ||
            v$.password.password.$pending.$response
          "
          class="p-error"
          >{{
            v$.password.password.required.$message.replace("Value", "Password")
          }}</small
        >
        <label
          for="confirmPassword"
          class="block text-900 font-medium mb-2"
          :class="{ 'p-error': v$.password.$invalid && submitted }"
          >Confirm password</label
        >
        <InputText
          id="confirmPassword"
          type="password"
          v-model="v$.password.confirm.$model"
          @keyup.enter="submit"
          class="w-full mb-3"
          :class="{
            'p-invalid': v$.password.confirm.$invalid && submitted,
            'border-green-400':
              !v$.password.confirm.$invalid && v$.password.confirm.$dirty,
          }"
        />
        <small
          v-if="v$.password.confirm.$invalid && v$.password.confirm.$dirty"
          class="p-error"
          >Passwords do not match.</small
        >
        <small
          v-else-if="
            !v$.password.confirm.$invalid && v$.password.confirm.$dirty
          "
          class="text-green-400"
          >Passwords are a match.</small
        >
        <div class="flex justify-content-center flex-wrap">
          <Button
            class="w-1/4 mr-2"
            @click="this.$router.go(-1)"
            icon="pi pi-arrow-left"
          >
          </Button>
          <Button
            label="Sign Up"
            icon="pi pi-user"
            class="w-1/4"
            v-on:click="submit(this.v$.invalid)"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryService from "../../service/CountryService";
import UserService from "../../service/UserService";
import { email, required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  // setup: () => ({ v$: useVuelidate() }),

  data() {
    return {
      display: false,
      v$: useVuelidate(),
      dropdownValues: [
        { name: "Same timezone", code: "same" },
        { name: "+/- 3 hours", code: "3h" },
        { name: "Indifferent", code: "indifferent" },
      ],
      dropdownValue: { name: "Indifferent", code: "indifferent" },
      selectedAutoValue: null,
      autoFilteredValue: [],
      first_name: "",
      last_name: "",
      email: "",
      // password: "",
      password: {
        password: "",
        confirm: "",
      },
      submitted: false,
      xlm_public_key: "",
    };
  },
  countryService: null,
  userService: null,
  created() {
    this.countryService = new CountryService();
    this.userService = new UserService();
  },
  mounted() {
    this.countryService.getCountries().then((data) => (this.autoValue = data));
  },
  validations() {
    return {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      // password: {
      //   required,
      // },\
      password: {
        password: { required },
        confirm: { sameAsPassword: sameAs(this.password.password) },
      },
    };
  },
  methods: {
    searchCountry(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredValue = [...this.autoValue];
        } else {
          this.autoFilteredValue = this.autoValue.filter((country) => {
            return country.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 20);
    },
    open() {
      this.display = true;
    },
    close() {
      this.display = false;
    },
    submit(isFormValid) {
      this.submitted = true;
      if (this.v$.$invalid) {
        // alert("Please enter a valid");
        return;
      } else {
        var country = null;
        if (this.selectedAutoValue) {
          country = this.selectedAutoValue["name"];
        }

        this.userService
          .createUser({
            name: this.first_name + " " + this.last_name,
            email: this.email,
            country: country,
            password: this.password["password"],
            time_zone_pref: this.dropdownValue["name"],
            xlm_public_key: this.xlm_public_key,
          })
          .then((data) => {
            if ("error" in data) {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data["error"],
                life: 3000,
              });
            } else {
              this.$router.push("/signup/questionnaire");
            }
          });
      }
    },
  },
};
</script>
